/********** Template CSS **********/
:root {
  --primary: #34AD54;
  --secondary: #FF9933;
  --light: #F6FFF2;
  --dark: #263A4F;
}

.btn {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  transition: .5s;
}

.btn-primary,
.btn-secondary {
  color: #FFFFFF;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 0;
  z-index: 99;
}

.navbar-dark .navbar-nav .nav-link {
  padding: 30px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-transform: uppercase;
  transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 15px;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  background: var(--secondary);
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link  {
      padding: 10px;
  }
}

@media (max-width: 576px) {
  .carousel-caption h4 {
      font-size: 18px;
      font-weight: 500 !important;
  }

  .carousel-caption h1 {
      font-size: 30px;
      font-weight: 600 !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.bg-hero {
  background: url(../src/img/carousel-1.jpg) top right no-repeat;
  background-size: cover;
}

@media (min-width: 991.98px) {
  .banner {
      position: relative;
      margin-top: -90px;
      z-index: 1;
  }
}

.bg-vegetable {
  /* background: linear-gradient(rgba(52, 173, 84, .2), rgba(52, 173, 84, .2)), url(../img/vegetable.png) bottom right no-repeat; */
  background-size: contain;
}

.bg-fruit {
  /* background: linear-gradient(rgba(255, 153, 51, .2), rgba(255, 153, 51, .2)), url(../img/fruit.png) bottom right no-repeat; */
  background-size: contain;
}

.service-item {
  box-shadow: 0 0 45px #EDEDED;
  transition: .5s;
}

.about i,
.service-item i {
  background-image: linear-gradient(var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: .5s;
}

.service-item:hover {
  color: var(--light);
  background: var(--primary) !important;
}

.service-item:hover i {
  background-image: linear-gradient(var(--light), var(--secondary));
}

.service-item:hover h4 {
  transition: .5s;
}

.service-item:hover h4 {
  color: var(--light);
}

.product-item {
  padding: 0 30px 30px 30px;
}

.product-item .btn-action {
  position: absolute;
  width: 100%;
  bottom: -40px;
  left: 0;
  opacity: 0;
  transition: .5s;
}

.product-item:hover .btn-action {
  bottom: 0;
  opacity: 1;
}

.product-item h5 {
  transition: .5s;
}

.product-item:hover h5 {
  opacity: 0;
}

.product-carousel::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 55%;
  bottom: 0;
  left: 0;
  /* background: url(../img/bg-product-1.png) left bottom no-repeat, url(../img/bg-product-2.png) right bottom no-repeat; */
  background-size: contain;
  background-color: var(--primary);
  z-index: -1;
}

.product-carousel .owl-nav {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.product-carousel .owl-nav .owl-prev,
.product-carousel .owl-nav .owl-next{
  position: relative;
  width: 55px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #FFFFFF;
  font-size: 22px;
  transition: .5s;
}

.product-carousel .owl-nav .owl-prev:hover,
.product-carousel .owl-nav .owl-next:hover {
  color: var(--secondary);
}

.bg-testimonial {
  /* background: url(../img/testimonial.jpg) top center no-repeat; */
  background-size: cover;
}

.testimonial-carousel {
  background: rgba(52, 173, 84, .7);
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: calc(100% + 46px);
  height: 46px;
  top: calc(50% - 23px);
  left: -23px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #FFFFFF;
  font-size: 22px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--secondary);
}

.testimonial-carousel .owl-item img {
  width: 120px;
  height: 120px;
}

.blog-item img {
  transition: .5s;
}

.blog-item:hover img {
  transform: scale(1.2);
}

.blog-overlay {
  position: absolute;
  padding: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: linear-gradient(rgba(52, 173, 84, 0), rgba(52, 173, 84, 1));
  z-index: 1;
}
.bg-board{
  background: linear-gradient(rgba(52, 173, 84, 0), rgba(52, 173, 84, 1));
}

.bg-footer {
  /* background: linear-gradient(rgba(52, 173, 84, .7), rgba(52, 173, 84, .7)), url(../img/footer.png) center bottom no-repeat; */
  background-size: contain;
}



@media (min-width: 991.98px) {
  .bg-footer {
      margin-top: 90px !important;
  }
}