.img2{
    height: 200px;
    width: 500px;
    border-radius: 15px;
    /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
}

.box{
    border-radius: 15px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.box-2 {
    height: 500px;
    width: 400px;
    border-radius: 15px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.farmar{
    height: 500px;
}
.product{
    height: 500px;
    width: 500px;
    /* height: 70vh;
    width: 34vw; */
}
.product-image{
    /* height: 500px;
    width: 500px; */
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.bg-ground{
    height: 350px;
}
.wmb-5{
    margin-bottom: 3rem !important;
}
.padd{
    padding: 1rem !important;
}
@media (min-width: 991.98px) {
    .banner2 {
        position: relative;
        margin-top: -200px;;
        z-index: 1;
    }
  }

@media (max-width: 576px) {

    .farmar{
        height: 400px;
    }
    .product{
        /* height: 500px;
        width: 500px; */
        height: 40vh;
        width: 84vw;
    }
    .bg-ground{
        height: auto;
    }
    .box-2 {
        height: 400px;
        width: auto;
        border-radius: 15px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }


}